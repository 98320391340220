<mat-card class="no-padding">
    <qbit-table-header title="PERMISSIONS" [options]="tableOptions">
        <add-button (add)="addPermission()" *appPermissions="permissionForAdding"></add-button>
    </qbit-table-header>

    <qbit-table-filters *ngIf="searchMapping && searchMapping.length" [searchMapping]="searchMapping"
        [hiddenFilters]="hiddenFilters" [filters]="searchParams" (filtersChanged)="onSearch($event)" label="SEARCH_BY">
    </qbit-table-filters>

    <table mat-table [dataSource]="dataSource" matSort class="hoverable" (matSortChange)="sortChanged($event)"
        [matSortDirection]="sortDir" [matSortActive]="sortId">
        <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'USER'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <qbit-chip-link *ngIf="row.user | asyncp as user" link="/users/{{user.id}}" icon="person"
                    [label]="user.username || 'not-set'">
                </qbit-chip-link>
            </td>
        </ng-container>

        <ng-container matColumnDef="account">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'ACCOUNT'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <qbit-chip-link *ngIf="row.account | asyncp as account" link="/accounts/{{account.id}}" icon="group"
                    [label]="account.name || 'not-set'">
                </qbit-chip-link>
            </td>
        </ng-container>

        <ng-container matColumnDef="grants">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'GRANTS'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <mat-chip-list>
                    <mat-chip class="mat-chip mat-primary mat-standard-chip" *ngFor="let grant of row.grants">
                        <span class="text-extra-small">{{grant | translate}}</span>
                    </mat-chip>
                </mat-chip-list>
            </td>
        </ng-container>

        <ng-container matColumnDef="updated_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'UPDATED'|translate}} </th>
            <td mat-cell *matCellDef="let row"> <i class="date">{{row.updated_at|date:'short'}}</i> </td>
        </ng-container>

        <ng-container matColumnDef="options" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Item options menu"
                    (click)="$event.preventDefault(); $event.stopPropagation()">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button *appPermissions="permissionForRemoving" mat-menu-item class="col-error"
                        (click)="openRemoveConfirmation(row.id)">
                        <mat-icon class="mat-icon material-icons mat-icon-small">delete</mat-icon>
                        <span>{{'REMOVE'|translate}}</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <tr class="tr-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="permissionClicked(row)">
        </tr>
    </table>

    <caste-list-loading *ngIf="isLoading && !hasData"></caste-list-loading>

    <div class="list-no-items" *ngIf="!hasData && !isLoading">
        <p class="text-light">{{'NO_ITEMS'|translate}}</p>
    </div>


    <mat-paginator [length]="totalItems" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageChanged($event)"></mat-paginator>
</mat-card>