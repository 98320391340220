<mat-card class="no-padding">
    <mat-card-header class="padd-15" style="margin: 0">
        <mat-card-title style="margin: 0">
            <div class="flex-row center">
                <ngx-avatar size="25" [name]="item ? item.name: ''"></ngx-avatar>
                <span class="title text-mid marg-left-10">{{item ? item.name: '...'}}</span>
            </div>
        </mat-card-title>
        <div class="flex-row center h v abs-center-right">
            <qbit-chip-link *ngIf="item.application | asyncp as application" link="/applications/{{application.id}}"
                icon="apps" [label]="application.name">
            </qbit-chip-link>

            <button mat-icon-button class="marg-left-10" (click)="editAccount()">
                <mat-icon>edit</mat-icon>
            </button>
        </div>
    </mat-card-header>
</mat-card>