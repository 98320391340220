<div class="kvp">
    <div *ngIf="hidden" class="kvp-hidden-items padd-10 center v">
        <p class="small-text">{{'KVP_ITEMS_HIDDEN'|translate}}</p>
        <button mat-button matTooltip="Show and edit items" (click)="unhide()" style="margin-right: 10px">
            <span class="center h v">
                <i class="fas fa-edit"></i><span class="small-text">{{'EDIT'|translate}}</span>
            </span>
        </button>
    </div>
    <div *ngIf="!hidden" class="kvp-list-container">
        <div *ngIf="showHidding" style="border-bottom: 1px solid #ddd" class="center v padd-10">
            <p class="small-text">{{title|translate}}</p>
            <button mat-button matTooltip="Show and edit items" (click)="hide()" style="margin-right: 10px">
                <span class="center h v">
                    <i class="fas fa-eye-slash"></i><span class="small-text">{{'HIDE'|translate}}</span>
                </span>
            </button>
        </div>
        <kvp-item [editValue]="false" [editKey]="false" [showCheckbox]="false" key="Key" value="Value"></kvp-item>

        <kvp-item *ngFor="let item of items; let i = index; trackBy: trackByFn" [key]="item.key" [value]="item.value"
            [editValue]="editValues" [editKey]="editKeys" [showCheckbox]="showCheckboxes" [active]="item.active"
            (onChange)="changed(i, $event)" (onRemove)="removeItem(i)" (onUp)="moveItem(i, -1)"
            (onDown)="moveItem(i, 1)" [canRemove]="items.length > 1" [canMoveDown]="i < items.length-1"
            [canMoveUp]="i > 0">
        </kvp-item>

        <div class="center v" *ngIf="showActions">
            <button mat-button (click)="reset()" [disabled]="loading" type="button"
                style="height: 100%;width: 50%; padding: 10px 0; margin:0">
                <span class="center h v">{{'RESET_DEFAULTS'|translate}}</span>
            </button>
            <button mat-button (click)="newItem()" style="height: 100%;width: 50%; padding: 10px 0; margin:0" type="button">
                <span class="center h v"><i class="fas fa-plus"></i> {{'ADD'|translate}}</span>
            </button>
        </div>
    </div>
</div>