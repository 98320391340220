<!-- The following components only render if user is logged in and has User Permissions -->
<div *ngIf="user$.hasUser()" style="height: calc(100vh - 50px)">
    <caste-toolbar *appPermissions="permissionUser"></caste-toolbar>
    <qbit-sidemenu *appPermissions="permissionUser">
        <router-outlet></router-outlet>
    </qbit-sidemenu>
</div>

<!-- This router outlet only used when user is not logged in (for public routes) -->
<div *ngIf="!user$.hasUser()">
    <ng-container>
        <router-outlet></router-outlet>
    </ng-container>
</div>
