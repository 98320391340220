<qbit-dialog-header title="{{'DELETE_ITEM'|translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper">
    <div class="padd-20">
        <p class="subhead no-margin">{{'DELETE_ITEM_CONFIRM'|translate}}</p>
        <p class="text-mid">{{'DELETE_ITEM_CONFIRM_DESC'|translate}}</p>

        <div class="flex-row center justify-end marg-top-30 dialog-actions">
            <button mat-button (click)="close()">
                {{'CANCEL'|translate}}
            </button>
            <button mat-raised-button cdkFocusInitial (click)="proceed()" color="warn">
                {{'DELETE'|translate}}
            </button>
        </div>
    </div>
</div>