<qbit-dialog-header title="{{'CREATE_APPLICATION'|translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper">
    <form class="padd-20" [formGroup]="applicationDetailsForm" (ngSubmit)="addApplication()">
        <div class="row">
            <div class="col-lg-12">
                <h4 class="title-lg marg-bottom-20">
                    {{'NEW_APPLICATION' | translate}}
                </h4>

                <mat-form-field>
                    <mat-label>{{'NAME' | translate}}</mat-label>
                    <input id="name" matInput placeholder="Enter your name" formControlName="name">
                    <mat-error *ngIf="name.invalid && (name.dirty) && name.errors.required">
                        {{'NAME_REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'REALM' | translate}}</mat-label>
                    <input id="realm" matInput placeholder="Enter the realm for the application"
                        formControlName="realm">
                    <mat-error *ngIf="realm.invalid && (realm.dirty) && realm.errors.required">
                        {{'REALM_REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <div class="flex-row end">
                    <qbit-loadable-button [id]="'createApplication'" [disabled]="applicationDetailsForm.invalid || isLoading"
                        [loading]="isLoading">
                        {{(isLoading ? 'ADDING': 'ADD') |translate}}
                    </qbit-loadable-button>
                </div>
            </div>
        </div>
    </form>
</div>