<div class="bootstrap-wrapper padd-v-20 padd-h-20 ">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12" style="padding: 0">
            <div class="row">
                <div class="col-lg-12">
                    <h4 class="title">
                        {{'STATISTICS'|translate}}
                    </h4>
                </div>
                <div class="col-xlg-3 col-lg-6 col-md-6 col-sm-6 col-12" *appPermissions="permissionShowUsers">
                    <mat-card>
                        <app-aggregate icon="group" title="ACCOUNTS" [value]="accountsCount | async"
                            [valueColor]="'blue'">
                        </app-aggregate>
                    </mat-card>
                </div>
                <div class="col-xlg-3 col-lg-6 col-md-6 col-sm-6 col-12">
                    <mat-card>
                        <app-aggregate icon="apps" title="APPLICATIONS" [value]="applicationsCount | async"
                            [valueColor]="'blue'"></app-aggregate>
                    </mat-card>
                </div>
                <div class="col-xlg-3 col-lg-6 col-md-6 col-sm-6 col-12">
                    <mat-card>
                        <app-aggregate icon="person" title="USERS" [value]="usersCount | async" [valueColor]="'blue'">
                        </app-aggregate>
                    </mat-card>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <h4 class="title">
                        {{'Account Info'|translate}}
                    </h4>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                    <mat-card>
                        <app-aggregate title="ACCOUNT" [value]="userType" icon="face" valueColor="purple">
                        </app-aggregate>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>