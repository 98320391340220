<qbit-dialog-header title="{{'CREATE_USER'|translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper">
    <form class="padd-20" [formGroup]="userForm" (ngSubmit)="add()">
        <div class="row">
            <div class="col-lg-12">
                <p class="text-small marg-bottom-20">
                    {{'CREATE_USER_DESC' | translate}}
                </p>

                <sa-application-selector *ngIf="application | asyncp as app" userType="admin" [userType]="userRole"
                    (itemChange)="application = $event;">
                </sa-application-selector>

                <sa-application-selector *ngIf="!application" [userType]="userRole" [(item)]="application">
                </sa-application-selector>

                <sa-admin-account-selector *ngIf="account | asyncp as acc" [userType]="userRole" [item]="acc"
                    (itemChange)="account = $event"></sa-admin-account-selector>
                    
                <sa-admin-account-selector *ngIf="!account" [userType]="userRole" [(item)]="account">
                </sa-admin-account-selector>

                <caste-autocomplete-list-input [(value)]="roles"></caste-autocomplete-list-input>

                <mat-form-field>
                    <mat-label>{{'USERNAME' | translate}}</mat-label>
                    <input id="username" matInput placeholder="Enter Username" formControlName="username">
                    <mat-error *ngIf="username.invalid && (username.dirty) && username.errors.required">
                        {{'NAME_REQUIRED' | translate}}
                    </mat-error>
                    <mat-error *ngIf="username.invalid && (username.dirty) && username.errors.email">
                        {{'INVALID_EMAIL' | translate}}
                    </mat-error>
                </mat-form-field>

                <div class="flex-row end">
                    <qbit-loadable-button [id]="'createUserBtn'" [disabled]="userForm.invalid || isLoading"
                        [loading]="isLoading">
                        {{(isLoading ? 'ADDING': 'ADD') |translate}}
                    </qbit-loadable-button>
                </div>
            </div>
        </div>
    </form>
</div>