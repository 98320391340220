<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="User menu">
    <ngx-avatar *ngIf="user$.user" size="35" [name]="user$.user.username"></ngx-avatar>
</button>
<mat-menu #menu="matMenu">
    <div class="padd-h-20 padd-v-15 column center" *ngIf="user$.user">
        <h3 class="no-margin">{{user$.user.username}}</h3>
        <code *appPermissions="adminPermission" class="admin-badge">ADMIN</code>
    </div>
    <hr>
    <!-- <button routerLinkActive="active" routerLink="/profile" mat-menu-item aria-label="Goes to Profile">
        <mat-icon class="icon-small">face</mat-icon>
        <span>{{'PROFILE'|translate}}</span>
    </button> -->
    <button mat-menu-item aria-label="Exits the app" (click)="logout()">
        <mat-icon class="icon-small">exit_to_app</mat-icon>
        <span>{{'LOGOUT'|translate}}</span>
    </button>
</mat-menu>