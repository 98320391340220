<qbit-dialog-header title="{{'CREATE_PERMISSION'|translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper">
    <form class="padd-20" [formGroup]="permissionDetailsForm" (ngSubmit)="addPermission()">
        <div class="row">
            <div class="col-lg-12">
                <p class="text-mid">{{'CREATE_PERMISSION_DESC'|translate}}</p>

                <sa-admin-account-selector [disabled]="data.account" [(item)]="account"
                    [filters]="{application_id: user && user.application.id}">
                </sa-admin-account-selector>

                <sa-user-selector [disabled]="data.user" [userType]="userType" [(item)]="user"
                    [filters]="{account_id: account && account.id, application_id: account && account.application.id}">
                </sa-user-selector>

                <caste-autocomplete-list-input label="SELECT_GRANTS" newItemLabel="NEW_GRANT"
                    [allValues]="availableGrants" [(value)]="grants"></caste-autocomplete-list-input>

                <div class="flex-row end">
                    <qbit-loadable-button [id]="'createPermission'"
                        [disabled]="permissionDetailsForm.invalid || !user || (!account && !data.account_id) || grants.length <= 0 || isLoading"
                        [loading]="isLoading">
                        {{(isLoading ? 'ADDING': 'ADD') |translate}}
                    </qbit-loadable-button>
                </div>
            </div>
        </div>
    </form>
</div>