<mat-card class="no-padding">
    <qbit-table-header title="ACCOUNTS" [options]="tableOptions">
        <ng-container *ngIf="showAdd">
            <add-button (add)="addAccount()" *appPermissions="permissionForAdding"></add-button>
        </ng-container>
    </qbit-table-header>

    <qbit-table-filters *ngIf="searchMapping && searchMapping.length" [searchMapping]="searchMapping"
        [filters]="searchParams" (filtersChanged)="onSearch($event)" label="SEARCH_BY">
    </qbit-table-filters>

    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChanged($event)"
        [matSortDirection]="sortDir" [matSortActive]="sortId" class="hoverable">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'NAME'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <div class="flex-row center">
                    <ngx-avatar size="25" [name]="row.name"></ngx-avatar>
                    <span class="marg-left-10">{{row.name}}</span>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="roles">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'ROLES'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <mat-chip-list>
                    <mat-chip class="mat-chip mat-primary mat-standard-chip {{role.className}}"
                        *ngFor="let role of row.roles">{{role.name|translate}}</mat-chip>
                </mat-chip-list>
            </td>
        </ng-container>

        <ng-container matColumnDef="application">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'APPLICATION'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <qbit-chip-link *ngIf="row.application | asyncp as application" link="/applications/{{application.id}}"
                    icon="apps" [label]="application.name">
                </qbit-chip-link>
            </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'CREATED'|translate}} </th>
            <td mat-cell *matCellDef="let row"> <i class="date">{{row.created_at|date:'short'}}</i> </td>
        </ng-container>

        <ng-container matColumnDef="updated_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'UPDATED'|translate}} </th>
            <td mat-cell *matCellDef="let row"> <i class="date">{{row.updated_at|date:'short'}}</i> </td>
        </ng-container>

        <ng-container matColumnDef="options" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Item options menu"
                    (click)="$event.preventDefault(); $event.stopPropagation()">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item class="col-error" (click)="openRemoveConfirmation(row.id)">
                        <mat-icon class="mat-icon material-icons mat-icon-small">delete</mat-icon>
                        <span>{{'REMOVE'|translate}}</span>
                    </button>

                    <button mat-menu-item (click)="editAccount(row)">
                        <mat-icon class="mat-icon material-icons mat-icon-small">edit</mat-icon>
                        <span>{{'EDIT'|translate}}</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <tr class="tr-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" routerLink="/accounts/{{row.id}}">
        </tr>
    </table>

    <caste-list-loading *ngIf="isLoading && !hasData"></caste-list-loading>

    <div class="list-no-items" *ngIf="!hasData && !isLoading">
        <p class="text-light">{{'NO_ITEMS'|translate}}</p>
    </div>


    <mat-paginator [length]="totalItems" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageChanged($event)"></mat-paginator>
</mat-card>